// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "controllers";
import "template";
import Swal from "sweetalert2";
import LocalTime from "../lib/local-time";
import { Turbo } from "@hotwired/turbo-rails";

import "bootstrap";
import "trix";
import "@rails/actiontext";
import "chartkick/chart.js";

import "stylesheets/application.scss";
import "trix/dist/trix.css";
import "lib/keenicons/duotone/style.css";
import "lib/keenicons/outline/style.css";
import "lib/keenicons/solid/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@yaireo/tagify/dist/tagify.css";

Turbo.config.forms.confirm = (message, element) => {
  const confirmStyles = {
    warning: "btn btn-danger",
    info: "btn btn-primary",
    error: "btn btn-danger"
  }

  let title, body, confirmButtonText, cancelButtonText, type;
  try {
    ({ title, body, confirmButtonText, cancelButtonText, type } = JSON.parse(message));
  } catch (error) {
    body = message;
  }

  type ||= "warning";
  title ||= "Você tem certeza?";
  cancelButtonText ||= "Manter";
  confirmButtonText ||= "Sim, remover";

  return new Promise((resolve, _reject) => {
    Swal.fire({
      title,
      text: body,
      icon: type,
      buttonsStyling: false,
      cancelButtonText,
      confirmButtonText,
      customClass: {
        cancelButton: "btn btn-secondary me-2",
        confirmButton: confirmStyles[type],
      },
      reverseButtons: true,
      showCancelButton: true,
    }).then((confirmed) => {
      resolve(confirmed && confirmed.value == true);
    });
  });
};

LocalTime.start();

window.process = { env: {} };
